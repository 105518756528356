import React from 'react'
import '../assets/css/About.styl'
function About() {
        return (
            <div className="about flex-justify-center flex-column">
               <div className="banner flex-items-center flex-justify-center bold">
               </div>
               <div className="flex-column flex-items-center">
                    <div className="about-title fs-36 mt-36 bold">
                        <div className="tit">
                            关于我们
                        </div>
                    </div>
                    <div className="about-content fs-24 text-center">
                        <div>
                        FEIJIA TECHNOLOGY LIMITED
                        </div>
                        <div className="mt-30">
                        00852-39733878
                        </div>
                        <div className="mt-30">
                        ROOM 1003,10/F,TOWER 1 LIPPO CENTRE,89 QUEENSWAY,ADMIRALTY HK
                        </div>
                    </div>
               </div>
            </div>
        );
    }
export default About;